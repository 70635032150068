:root {
    --slider-width: 2.5em;
    --slider-height: 1.5em;
}
.slider {
    position: relative;
    width: var(--slider-width);
    height: var(--slider-height);
    background-color: grey;
    border-radius: 10em;
    transition: all 350ms ease;
}
.sliderOn {
    background-color: #36ca00;
}
.ball {
    position: absolute;
    height: var(--slider-height);
    width: var(--slider-height);
    background: white;
    border-radius: 50%;
    transition: all 350ms linear;
    box-shadow: 0 0 0.5em black;
}

.ballOff {
    left: 0;
}
.ballOn {
    left: calc(var(--slider-width) - var(--slider-height));
}

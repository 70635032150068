.WhiteBg {
    background: white;
}

.Transition {
    transition: all 0.4s;
}

.Hidden {
    opacity: 0;
}

/* Dark mode */
@media (prefers-color-scheme: dark) {
  img {
    opacity: .75;
    transition: opacity .5s ease-in-out;
  }

  img:hover {
    opacity: 1;
  }
} /* End Dark mode */

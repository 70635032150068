.FlagDiv {
    display: inline-block;
    position: relative;
    height: 50px;
    width: 90px;
    padding-left: 10px;
    vertical-align: middle;
    cursor: pointer;
}

.Flag {
    height: 50px;
    width: 90px;
}

.FlagDiv p {
    position: absolute;
    top: 0px;
    left: 10px;
    height: 50px;
    width: 90px;
    text-align: center;
    user-select: none;
}

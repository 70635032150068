/* Reset style */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* End reset style */

select {
    appearance: none;
    background-color: transparent;
    border: none;
    margin: 0;
    padding: 0;
    font-family: inherit;
    font-size: inherit;
    cursor: inherit;
    line-height: inherit;
    outline: none;
    font-weight: bold;
    cursor: pointer;
}

/* Dark mode */
@media (prefers-color-scheme: dark) {
  select {
    color: white;
  }
} /* End Dark mode */

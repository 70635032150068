:root {
    --color-tooltip: #eeeeee;
}
.Grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(30rem, auto));
    grid-gap: 1rem;
}

.Border {
    border: 1px solid black;
    border-radius: 1rem;
}

.Padding {
    padding: 0.5rem;
}

.WindArrow {
    height: 15px;
    width: 15px;
}

.WeatherSymbolSmall {
    height: 20pt;
}

.BigFontSize {
    font-size: 150%;
}

.table {
    display: table;
}

.table-row {
    display: table-row;
}

.table-cell {
    display: table-cell;
    padding: 0 0.2rem;
    position: relative;
}

.table-cell:empty {
    padding: 0;
}

.colorRed {
    color: red;
}

.colorBlue {
    color: blue;
}

.colorOrange {
    color: orange;
}

.TooltipON div[data-tooltip]:hover {
    cursor: help;
}

.TooltipON div[data-tooltip]::before {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: var(--color-tooltip);
    transform: scale(0);
}

.TooltipON div[data-tooltip]:hover::before {
    content: attr(data-tooltip);
    position: absolute;
    padding: 0.2rem;
    bottom: 100%;
    left: 0;
    width: max-content;
    z-index: 2;
    transform: scale(1);
    transition: all 1s step-end;
}

.TooltipON div[data-tooltip]::after {
    content: '';
    transform: scale(0);
}
.TooltipON div[data-tooltip]:hover::after {
    content: '';
    position: absolute;
    width: 2rem;
    height: 2rem;
    background-color: var(--color-tooltip);
    z-index: 1;
    bottom: calc(100% - 1rem);
    left:0;
    clip-path: polygon(50% 100%, 0 0, 100% 0);
    transform: scale(1);
    transition: all 1s step-end;
}

/* Dark mode */
@media (prefers-color-scheme: dark) {
    .colorRed {
        color: red;
    }

    .colorBlue {
        color: cyan;
    }

    .colorOrange {
        color: orange;
    }

    .TooltipON div[data-tooltip]:hover::before {
        color: black;
    }
} /* End Dark mode */

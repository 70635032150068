.Settings {
    position: relative;
}

.Settings img {
    height: 1em;
    transform: rotate(0deg);
    transition: transform 350ms linear;
    cursor: pointer;
}

.Settings img[class=Rotate] {
    height: 1em;
    transform: rotate(90deg);
}

.Settings input[type=checkbox] {
    display: none;
}

.SettingsRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1em;
}

.SettingsDialog {
    position: absolute;
    right: 0;
    top: 100;
    background: white;
    padding: 0.5em;
    border: 1px solid black;
    border-radius: 1em;
    transition: transform 350ms linear;
    z-index: 2;
}

.SettingsDialogClosed {
    transform: scale(0);
}

.SettingsDialogOpened {
    transform: scale(1);
}

.CloseClickArea {
    position: absolute;
    background: transparent;
    top: 0;
    right: 0;
    z-index: 1;
    margin-top: -100vh;
    width: 100vw;
    height: 300vh;
}

/* Dark mode */
@media (prefers-color-scheme: dark) {
  .SettingsDialog {
    background: black;
    color: white;
    border: 1px solid grey;
  }
} /* End Dark mode */

summary {
    cursor: pointer;
}

.MetWarningMessage {
    background: lightblue;
    border: 1px solid darkblue;
    padding: 3px;
}

.MetWarningRed {
    background: red;
    border: 1px solid darkred;
    padding: 3px;
}

.MetWarningOrange {
    background: orange;
    border: 1px solid darkorange;
    padding: 3px;
}

.MetWarningYellow {
    background: yellow;
    border: 1px solid black;
    padding: 3px;
}

/* Dark mode */
@media (prefers-color-scheme: dark) {
  .MetWarningMessage {
    background: darkblue;
    color: white;
  }

  .MetWarningRed {
    background: darkred;
    color: white;
  }

  .MetWarningOrange {
    background: darkorange;
    color: black;
  }

  .MetWarningYellow {
    background: gold;
    color: black;
  }
} /* End Dark mode */
